var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-pagination", {
    attrs: {
      "current-page": _vm.currentPage,
      "page-size": _vm.pageSize,
      "page-sizes": [10, 20, 30, 40],
      layout: "total, sizes, prev, pager, next, jumper",
      total: _vm.pageTotal,
    },
    on: {
      "update:currentPage": function ($event) {
        _vm.currentPage = $event
      },
      "update:current-page": function ($event) {
        _vm.currentPage = $event
      },
      "size-change": _vm.handleSizeChange,
      "current-change": _vm.handleCurrentChange,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }