<template>
  <!-- 反馈详情 -->
  <div class="feedbackDetial">
    <div class="return" @click="closeDeal">
      <i class="iconfont its_ba_fanhui"></i>
    </div>
    <div class="feedbackInfo">
      <div class="state">
        <img
          v-if="query.status == 0"
          src="@/assets/images/home/gy_weichuli.png"
          width="32px"
          height="32px"
          alt=""
        />
        <img
          v-if="query.status == 1"
          src="@/assets/images/home/gy_genjinzhong.png"
          width="32px"
          height="32px"
          alt=""
        />
        <img
          v-if="query.status >= 2"
          src="@/assets/images/home/gy_yichuli.png"
          width="32px"
          height="32px"
          alt=""
        />

        <i class="iconfont its_gy_genjinzhong"></i>
        <h4>
          {{
            query.status == 0
              ? "未处理"
              : query.status == 1
              ? "跟进中"
              : query.status == 2
              ? "已处理"
              : "已关闭"
          }}
        </h4>
      </div>
      <el-row class="info">
        <el-col :span="24">
          <el-col :span="3" class="label">用户手机：</el-col>
          <el-col :span="5">{{
            Info.passengerPhone ? Info.passengerPhone : "无"
          }}</el-col>
          <el-col :span="3" class="label">联系方式：</el-col>
          <el-col :span="5" style="color: #336ffe">{{
            Info.passengerContactInformation
              ? Info.passengerContactInformation
              : "无"
          }}</el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="3" class="label">反馈类型：</el-col>
          <el-col :span="5">{{
            Info.feedbackTypeName ? Info.feedbackTypeName : "无"
          }}</el-col>
          <el-col :span="3" class="label">反馈时间：</el-col>
          <el-col :span="5">{{
            Info.createTime ? Info.createTime : "无"
          }}</el-col>
        </el-col>
        <el-col v-if="Info.orderId" :span="24">
          <el-col :span="3" class="label">订单编号：</el-col>
          <el-col :span="21">{{ Info.orderId }}</el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="3" class="label">反馈内容：</el-col>
          <el-col :span="21">{{
            Info.complaintContent ? Info.complaintContent : "无"
          }}</el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="3" class="label">附件：</el-col>
          <el-col :span="21">
            <img
              v-for="item in Info.annexes"
              :key="item"
              :src="imgURL + item"
              alt=""
              @click="
                () => {
                  imgPopupShow = true;
                  imgSrc = imgURL + item;
                }
              "
            />
            <span
              v-if="!videoSrc && (!Info.annexes || Info.annexes.length == 0)"
              >无</span
            >
            <video
              v-if="videoSrc"
              :src="videoSrc"
              @click="
                () => {
                  videoPopupShow = true;
                }
              "
            ></video>
          </el-col>
        </el-col>
      </el-row>
    </div>

    <div class="content">
      <div class="left">
        <div class="other">
          <div ref="referList" class="referList">
            <template v-for="(item, index) in list">
              <div :key="'date' + index" class="textDate">
                {{ item.createTime_format }}
              </div>
              <div
                :key="index"
                :class="['list', item.replyFrom == 2 ? '' : 'right-side']"
              >
                <div class="contact">
                  <img
                    v-if="item.replyFrom == 2"
                    :src="getImgSrc() || avatar"
                    width="32px"
                    height="32px"
                    alt=""
                  />
                  <img v-else :src="official" width="32px" height="32px" />
                  <span
                    style="
                      line-height: 35px;
                      margin-left: 5px;
                      vertical-align: top;
                    "
                    >{{ item.userName }}</span
                  >
                </div>

                <p v-if="item.isImg" class="text img">
                  <img
                    :src="item.replyContent"
                    @click="
                      () => {
                        imgPopupShow = true;
                        imgSrc = item.replyContent;
                      }
                    "
                  />
                  <span
                    class="mask"
                    :style="{ height: 100 - item.percent + '%' }"
                  >
                    <i
                      v-if="item.sending"
                      class="el-icon-loading"
                      style="position: absolute; left: 10px; top: 10px"
                    ></i>
                  </span>
                </p>
                <p v-else-if="item.contentType == 2" class="text img">
                  <img
                    :src="imgURL + item.replyContent"
                    @click="
                      () => {
                        imgPopupShow = true;
                        imgSrc = imgURL + item.replyContent;
                      }
                    "
                  />
                </p>
                <p v-else class="text">
                  <i v-if="item.sending" class="el-icon-loading"></i>
                  {{ item.replyContent }}
                </p>
              </div>
            </template>
          </div>
          <div class="replay">
            <el-input
              v-model="value"
              :rows="3"
              :disabled="!(Info.status == 0 || Info.status == 1)"
              type="textarea"
              placeholder="这里输入内容..."
            />
          </div>
          <div class="footer">
            <el-upload
              class="avatar-uploader"
              :action="apiBaseUrl + 'manage/uploadImage/file/upload'"
              :show-file-list="false"
              name="files"
              :multiple="false"
              :before-upload="beforeAvatarUpload"
              :on-success="uploadSuccess"
              :on-progress="uploadProgress"
              :headers="uploadHeader"
              :disabled="!(Info.status == 0 || Info.status == 1)"
            >
              <div class="upload">
                <i class="el-icon-plus"></i>
              </div>
            </el-upload>
            <el-button
              type="primary"
              size="small"
              :disabled="!(Info.status == 0 || Info.status == 1)"
              @click="replayBtn"
              >回 复</el-button
            >
          </div>
        </div>
      </div>
      <div class="right">
        <div class="other">
          <div class="deal">
            <el-form :model="form" label-position="left" label-width="80px">
              <el-form-item label="处理意见："> </el-form-item>
              <el-form-item label-width="0">
                <el-input
                  v-model="Info.handlingOpinion"
                  type="textarea"
                  placeholder="在这里输入处理意见"
                  :disabled="!(Info.status == 0 || Info.status == 1)"
                  :rows="5"
                />
              </el-form-item>
              <el-form-item label="处理人：">
                {{ Info.handlerName ? Info.handlerName : "" }}
              </el-form-item>
              <el-form-item label="处理时间：">
                <span v-if="Info.handleTime">
                  {{ Info.handleTime }}
                </span>
                <!-- <span v-else> 待处理 </span> -->
              </el-form-item>
            </el-form>
          </div>
          <div class="footer">
            <el-button
              type="danger"
              style="margin-top: 17px"
              size="small"
              :disabled="!(Info.status == 0 || Info.status == 1)"
              @click="handelerAndClose"
              >处理并关闭反馈</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 图片预览 -->
    <div
      v-show="imgPopupShow"
      class="img_popup"
      @click="
        () => {
          this.imgPopupShow = false;
        }
      "
    >
      <img :src="imgSrc" />
    </div>
    <!-- 视频预览 -->
    <div
      v-show="videoPopupShow"
      class="video_popup"
      @click="
        () => {
          this.videoPopupShow = false;
        }
      "
    >
      <video v-if="videoPopupShow" :src="videoSrc" muted autoplay></video>
    </div>
  </div>
</template>

<script>
import pagination from "../../../components/pagination/pagination.vue";
import {
  feedbackDetailInfoAPI,
  feedbackReplyAPI,
  feedbackHandleAPI,
} from "@/api/lib/api.js";
import host from "@/api/static/host.js";
import moment from "moment";
export default {
  components: { pagination },
  data() {
    return {
      form: {},
      list: [],
      avatar: require("@/assets/images/home/header.png"),
      official: require(`@/assets/images/home/${this.$configs.logoImg}`),
      value: "",
      Info: {},
      annexes: [],
      uploadHeader: {},
      imgURL: host.imgURL,
      apiBaseUrl: host.apiBaseUrl,
      imgSrc: "",
      query: {},
      imgPopupShow: false,
      videoPopupShow: false,
      videoSrc: null,
    };
  },
  created() {
    this.query = this.$route.query;
    this.getFeedbackDetialInfo(this.query);
    this.uploadHeader.token = window.sessionStorage.getItem("token");
  },
  mounted() {
    sessionStorage.setItem("complaintFeedbackFlag", true);
  },
  methods: {
    //获取反馈对象的头像
    getImgSrc() {
      if (this.Info.passengerIcon) {
        if (this.Info.passengerIcon.includes("https")) {
          return this.Info.passengerIcon;
        } else {
          return this.imgURL + this.Info.passengerIcon;
        }
      } else {
        return "";
      }
    },
    //查看反馈详情
    getFeedbackDetialInfo(query) {
      feedbackDetailInfoAPI(query).then((res) => {
        if (res.data) {
          this.Info = res.data;
          this.videoSrc = res.data.videoAnnexes[0]
            ? this.imgURL + res.data.videoAnnexes[0]
            : null;
          this.removeDate(res.data.answerList);
          this.list = res.data.answerList;
          this.$nextTick(() => {
            let child = this.$refs.referList.children;
            // child[child.length-1].scrollIntoView();
            if (child.length) {
              this.$refs.referList.scrollTo(
                0,
                child[child.length - 1].offsetTop + 500
              );
            }
          });
        }
      });
    },
    // 当时间跨度不大(小于30分钟)时 移除相近的时间
    removeDate(arr) {
      if (!arr.length) return;
      for (let a = 1; a < arr.length; a++) {
        try {
          let times =
            moment(arr[a].createTime).valueOf() -
            moment(arr[a - 1].createTime).valueOf();
          if (times > 1800000) {
            arr[a].createTime_format = moment(arr[a].createTime).format(
              "yyyy年MM月DD日 hh:mm"
            );
          }
        } catch (e) {}
      }
      arr[0].createTime_format = moment(arr[0].createTime).format(
        "yyyy年MM月DD日 hh:mm"
      );
    },
    // 返回
    closeDeal() {
      this.$router.go(-1);
    },
    // 回复
    replayBtn() {
      let obj = {
        replyContent: this.value,
        feedbackId: this.query.id,
        sending: true,
        replyFrom: 1,
        userName: "平台",
        contentType: 1,
        channelNo: this.query.channelNo,
      };
      if (this.value) {
        this.list.push(obj);
        this.$nextTick(() => {
          let child = this.$refs.referList.children;
          // child[child.length-1].scrollIntoView();
          this.$refs.referList.scrollTo(
            0,
            child[child.length - 1].offsetTop + 500
          );
          this.value = "";
        });
        feedbackReplyAPI(obj).then((res) => {
          obj.sending = false;
          if (this.query.status == 0) this.query.status = 1;
        });
      } else {
        this.$message.warning("请输入回复内容");
      }
    },
    // 处理反馈 然后关闭页面
    handelerAndClose() {
      if (this.Info.handlingOpinion) {
        feedbackHandleAPI({
          handlingOpinion: this.Info.handlingOpinion,
          id: this.Info.id,
        }).then((res) => {
          this.$message.success("已提交处理意见！");
          setTimeout(() => {
            this.$router.go(-1);
          }, 1000);
        });
      } else {
        this.$message.warning("请输入处理意见");
      }
    },
    // 文件上传前回调
    beforeAvatarUpload(file) {
      if (!this.fileUID) {
        this.fileUID = {};
      }
      let fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (res) => {
        let obj = {
          replyContent: res.target.result,
          feedbackId: this.query.id,
          sending: true,
          isImg: true,
          replyFrom: 1,
          percent: 0,
          userName: window.sessionStorage.getItem("realname"),
        };
        this.fileUID[file.uid] = obj;
        this.list.push(obj);
        this.$nextTick(() => {
          let child = this.$refs.referList.children;
          // child[child.length-1].scrollIntoView();
          this.$refs.referList.scrollTo(
            0,
            child[child.length - 1].offsetTop + 500
          );
        });
      };
      return true;
    },
    // 上传成功回调
    uploadSuccess(res, file) {
      let obj = this.fileUID[file.uid];
      feedbackReplyAPI({
        replyContent: res.data[0],
        feedbackId: obj.feedbackId,
        replyFrom: obj.replyFrom,
        contentType: 2,
        channelNo: this.query.channelNo,
      }).then(() => {
        obj.sending = false;
      });
    },
    // 上传进度
    uploadProgress(e, file) {
      this.fileUID[file.uid].percent = e.percent;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./feedbackDetail.scss";
</style>
