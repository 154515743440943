var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "feedbackDetial" }, [
    _c("div", { staticClass: "return", on: { click: _vm.closeDeal } }, [
      _c("i", { staticClass: "iconfont its_ba_fanhui" }),
    ]),
    _c(
      "div",
      { staticClass: "feedbackInfo" },
      [
        _c("div", { staticClass: "state" }, [
          _vm.query.status == 0
            ? _c("img", {
                attrs: {
                  src: require("@/assets/images/home/gy_weichuli.png"),
                  width: "32px",
                  height: "32px",
                  alt: "",
                },
              })
            : _vm._e(),
          _vm.query.status == 1
            ? _c("img", {
                attrs: {
                  src: require("@/assets/images/home/gy_genjinzhong.png"),
                  width: "32px",
                  height: "32px",
                  alt: "",
                },
              })
            : _vm._e(),
          _vm.query.status >= 2
            ? _c("img", {
                attrs: {
                  src: require("@/assets/images/home/gy_yichuli.png"),
                  width: "32px",
                  height: "32px",
                  alt: "",
                },
              })
            : _vm._e(),
          _c("i", { staticClass: "iconfont its_gy_genjinzhong" }),
          _c("h4", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.query.status == 0
                    ? "未处理"
                    : _vm.query.status == 1
                    ? "跟进中"
                    : _vm.query.status == 2
                    ? "已处理"
                    : "已关闭"
                ) +
                " "
            ),
          ]),
        ]),
        _c(
          "el-row",
          { staticClass: "info" },
          [
            _c(
              "el-col",
              { attrs: { span: 24 } },
              [
                _c("el-col", { staticClass: "label", attrs: { span: 3 } }, [
                  _vm._v("用户手机："),
                ]),
                _c("el-col", { attrs: { span: 5 } }, [
                  _vm._v(
                    _vm._s(
                      _vm.Info.passengerPhone ? _vm.Info.passengerPhone : "无"
                    )
                  ),
                ]),
                _c("el-col", { staticClass: "label", attrs: { span: 3 } }, [
                  _vm._v("联系方式："),
                ]),
                _c(
                  "el-col",
                  { staticStyle: { color: "#336ffe" }, attrs: { span: 5 } },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.Info.passengerContactInformation
                          ? _vm.Info.passengerContactInformation
                          : "无"
                      )
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "el-col",
              { attrs: { span: 24 } },
              [
                _c("el-col", { staticClass: "label", attrs: { span: 3 } }, [
                  _vm._v("反馈类型："),
                ]),
                _c("el-col", { attrs: { span: 5 } }, [
                  _vm._v(
                    _vm._s(
                      _vm.Info.feedbackTypeName
                        ? _vm.Info.feedbackTypeName
                        : "无"
                    )
                  ),
                ]),
                _c("el-col", { staticClass: "label", attrs: { span: 3 } }, [
                  _vm._v("反馈时间："),
                ]),
                _c("el-col", { attrs: { span: 5 } }, [
                  _vm._v(
                    _vm._s(_vm.Info.createTime ? _vm.Info.createTime : "无")
                  ),
                ]),
              ],
              1
            ),
            _vm.Info.orderId
              ? _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c("el-col", { staticClass: "label", attrs: { span: 3 } }, [
                      _vm._v("订单编号："),
                    ]),
                    _c("el-col", { attrs: { span: 21 } }, [
                      _vm._v(_vm._s(_vm.Info.orderId)),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-col",
              { attrs: { span: 24 } },
              [
                _c("el-col", { staticClass: "label", attrs: { span: 3 } }, [
                  _vm._v("反馈内容："),
                ]),
                _c("el-col", { attrs: { span: 21 } }, [
                  _vm._v(
                    _vm._s(
                      _vm.Info.complaintContent
                        ? _vm.Info.complaintContent
                        : "无"
                    )
                  ),
                ]),
              ],
              1
            ),
            _c(
              "el-col",
              { attrs: { span: 24 } },
              [
                _c("el-col", { staticClass: "label", attrs: { span: 3 } }, [
                  _vm._v("附件："),
                ]),
                _c(
                  "el-col",
                  { attrs: { span: 21 } },
                  [
                    _vm._l(_vm.Info.annexes, function (item) {
                      return _c("img", {
                        key: item,
                        attrs: { src: _vm.imgURL + item, alt: "" },
                        on: {
                          click: () => {
                            _vm.imgPopupShow = true
                            _vm.imgSrc = _vm.imgURL + item
                          },
                        },
                      })
                    }),
                    !_vm.videoSrc &&
                    (!_vm.Info.annexes || _vm.Info.annexes.length == 0)
                      ? _c("span", [_vm._v("无")])
                      : _vm._e(),
                    _vm.videoSrc
                      ? _c("video", {
                          attrs: { src: _vm.videoSrc },
                          on: {
                            click: () => {
                              _vm.videoPopupShow = true
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "left" }, [
        _c("div", { staticClass: "other" }, [
          _c(
            "div",
            { ref: "referList", staticClass: "referList" },
            [
              _vm._l(_vm.list, function (item, index) {
                return [
                  _c("div", { key: "date" + index, staticClass: "textDate" }, [
                    _vm._v(" " + _vm._s(item.createTime_format) + " "),
                  ]),
                  _c(
                    "div",
                    {
                      key: index,
                      class: ["list", item.replyFrom == 2 ? "" : "right-side"],
                    },
                    [
                      _c("div", { staticClass: "contact" }, [
                        item.replyFrom == 2
                          ? _c("img", {
                              attrs: {
                                src: _vm.getImgSrc() || _vm.avatar,
                                width: "32px",
                                height: "32px",
                                alt: "",
                              },
                            })
                          : _c("img", {
                              attrs: {
                                src: _vm.official,
                                width: "32px",
                                height: "32px",
                              },
                            }),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "line-height": "35px",
                              "margin-left": "5px",
                              "vertical-align": "top",
                            },
                          },
                          [_vm._v(_vm._s(item.userName))]
                        ),
                      ]),
                      item.isImg
                        ? _c("p", { staticClass: "text img" }, [
                            _c("img", {
                              attrs: { src: item.replyContent },
                              on: {
                                click: () => {
                                  _vm.imgPopupShow = true
                                  _vm.imgSrc = item.replyContent
                                },
                              },
                            }),
                            _c(
                              "span",
                              {
                                staticClass: "mask",
                                style: { height: 100 - item.percent + "%" },
                              },
                              [
                                item.sending
                                  ? _c("i", {
                                      staticClass: "el-icon-loading",
                                      staticStyle: {
                                        position: "absolute",
                                        left: "10px",
                                        top: "10px",
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                          ])
                        : item.contentType == 2
                        ? _c("p", { staticClass: "text img" }, [
                            _c("img", {
                              attrs: { src: _vm.imgURL + item.replyContent },
                              on: {
                                click: () => {
                                  _vm.imgPopupShow = true
                                  _vm.imgSrc = _vm.imgURL + item.replyContent
                                },
                              },
                            }),
                          ])
                        : _c("p", { staticClass: "text" }, [
                            item.sending
                              ? _c("i", { staticClass: "el-icon-loading" })
                              : _vm._e(),
                            _vm._v(" " + _vm._s(item.replyContent) + " "),
                          ]),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "replay" },
            [
              _c("el-input", {
                attrs: {
                  rows: 3,
                  disabled: !(_vm.Info.status == 0 || _vm.Info.status == 1),
                  type: "textarea",
                  placeholder: "这里输入内容...",
                },
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c(
                "el-upload",
                {
                  staticClass: "avatar-uploader",
                  attrs: {
                    action: _vm.apiBaseUrl + "manage/uploadImage/file/upload",
                    "show-file-list": false,
                    name: "files",
                    multiple: false,
                    "before-upload": _vm.beforeAvatarUpload,
                    "on-success": _vm.uploadSuccess,
                    "on-progress": _vm.uploadProgress,
                    headers: _vm.uploadHeader,
                    disabled: !(_vm.Info.status == 0 || _vm.Info.status == 1),
                  },
                },
                [
                  _c("div", { staticClass: "upload" }, [
                    _c("i", { staticClass: "el-icon-plus" }),
                  ]),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    disabled: !(_vm.Info.status == 0 || _vm.Info.status == 1),
                  },
                  on: { click: _vm.replayBtn },
                },
                [_vm._v("回 复")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "right" }, [
        _c("div", { staticClass: "other" }, [
          _c(
            "div",
            { staticClass: "deal" },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    model: _vm.form,
                    "label-position": "left",
                    "label-width": "80px",
                  },
                },
                [
                  _c("el-form-item", { attrs: { label: "处理意见：" } }),
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": "0" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "在这里输入处理意见",
                          disabled: !(
                            _vm.Info.status == 0 || _vm.Info.status == 1
                          ),
                          rows: 5,
                        },
                        model: {
                          value: _vm.Info.handlingOpinion,
                          callback: function ($$v) {
                            _vm.$set(_vm.Info, "handlingOpinion", $$v)
                          },
                          expression: "Info.handlingOpinion",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("el-form-item", { attrs: { label: "处理人：" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.Info.handlerName ? _vm.Info.handlerName : ""
                        ) +
                        " "
                    ),
                  ]),
                  _c("el-form-item", { attrs: { label: "处理时间：" } }, [
                    _vm.Info.handleTime
                      ? _c("span", [
                          _vm._v(" " + _vm._s(_vm.Info.handleTime) + " "),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-top": "17px" },
                  attrs: {
                    type: "danger",
                    size: "small",
                    disabled: !(_vm.Info.status == 0 || _vm.Info.status == 1),
                  },
                  on: { click: _vm.handelerAndClose },
                },
                [_vm._v("处理并关闭反馈")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.imgPopupShow,
            expression: "imgPopupShow",
          },
        ],
        staticClass: "img_popup",
        on: {
          click: () => {
            this.imgPopupShow = false
          },
        },
      },
      [_c("img", { attrs: { src: _vm.imgSrc } })]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.videoPopupShow,
            expression: "videoPopupShow",
          },
        ],
        staticClass: "video_popup",
        on: {
          click: () => {
            this.videoPopupShow = false
          },
        },
      },
      [
        _vm.videoPopupShow
          ? _c("video", {
              attrs: { src: _vm.videoSrc, muted: "", autoplay: "" },
              domProps: { muted: true },
            })
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }